import styled from 'styled-components';

const SidebarLayout = styled.div`
  padding-left: 144px;
  padding-bottom: 40px;
  @media only screen and (max-width: 1285px) {
    padding-left: 100px;
  }
  @media only screen and (max-width: 768px) {
    padding: 0 16px;
  }
`;

export default SidebarLayout;
