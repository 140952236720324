import { SignUpNetworkData, SupporterSignature } from 'common/store/types';

export const initialSignUpNetworkData: SignUpNetworkData = {
  about_network: [
    {
      label: 'Is critically ill',
      id: 1,
      status: false,
    },
    {
      label: 'Has passed away',
      id: 2,
      status: false,
    },
  ],
  about_you: [
    {
      label: 'My spouse or partner is critically ill',
      id: 1,
      status: false,
    },
    {
      label: 'I am critically ill',
      id: 2,
      status: false,
    },
    {
      label: 'My friend or family member is critically ill',
      id: 3,
      status: false,
    },
  ],
  have_partner: [
    {
      label: 'I have a partner',
      id: 1,
      status: false,
    },
    {
      label: 'I do not have a partner',
      id: 2,
      status: false,
    },
  ],
  how_did_you_know_person: [
    {
      label: 'They were my partner and/or co-parent',
      id: 1,
      status: false,
    },
    {
      label: 'They were my friend or family member</br>(not a partner or/co-parent)',
      id: 2,
      status: false,
    },
  ],
  add_partner_parents: [
    {
      label: 'Yes',
      id: 1,
      status: false,
    },
    {
      label: 'No',
      id: 2,
      status: false,
    },
  ],
  partner: {
    first_name: '',
    last_name: '',
    birthdate: '',
    email: '',
    relation: '',
  },
  family_friend: {
    first_name: '',
    last_name: '',
    birthdate: '',
    email: '',
  },
  children: [
    {
      first_name: '',
      last_name: '',
      birthdate: '',
      your_relation: '',
      partner_relation: '',
      email: '',
    },
  ],
  children_relation: {
    your_relation: '',
    known_through: '',
    family_friend_relation: '',
    refers_to_me_as: '',
  },
};

export const intialSupporterSignatureData : SupporterSignature = {
  user_id: 0,
  refers_me_as: '',
  signature: '',
};
