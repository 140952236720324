import intro1 from 'assets/images/intro/intro01@1.5.png';
import intro2 from 'assets/images/intro/intro02@1.5.png';
import intro3 from 'assets/images/intro/intro03@1.5.png';
import greenTick from 'assets/images/icons/green-right-tick.svg';
import defaultProfilePic from 'assets/images/icons/profilePicture.svg';
import { ESLogo, ESText } from 'assets/images';
import yourSupportHeart from 'assets/images/icons/yourSupportHeart.svg';
import cancelIcon from 'assets/images/icons/cancel_navy.svg';
import validIcon from 'assets/images/icons/valid-icon.svg';
import invalidIcon from 'assets/images/icons/invalid-icon.svg';
import eyeIcon from 'assets/images/icons/eye.svg';
import eyeOffIcon from 'assets/images/icons/eye-off.svg';
import mapIcon from 'assets/images/icons/mapIcon.svg';
import userIcon from 'assets/images/icons/user.svg';
import plusIcon from 'assets/images/icons/plus.svg';
import minusIcon from 'assets/images/icons/minus.svg';
import profilePictureNetwork from 'assets/images/icons/profilePictureNetwork.svg';
import crossIcon from 'assets/images/icons/cross.svg';
import uploadIcon from 'assets/images/icons/arrow-up-circle.svg';
import editImageIcon from 'assets/images/icons/edit-image.svg';
import calendar from 'assets/images/icons/calendar.svg';
import emptyMilestone from 'assets/images/icons/emptyCal.svg';
import milestoneIcon from 'assets/images/icons/milestone-black.svg';
import callIcon from 'assets/images/icons/call.svg';
import editIcon from 'assets/images/icons/edit-icon.svg';
import whatsappIcon from 'assets/images/icons/whatsapp.svg';
import facebookIcon from 'assets/images/icons/facebook.svg';
import instagramIcon from 'assets/images/icons/instagram.svg';
import calling from 'assets/images/icons/calling.svg';
import whatsapp from 'assets/images/icons/whatsapp-icon.svg';
import mail from 'assets/images/icons/mail.svg';
import userIconEdit from 'assets/images/icons/user-icon.svg';
import usersIcon from 'assets/images/icons/users-icon.svg';
import favoriteDefault from 'assets/images/icons/default-favorite.svg';
import heartIcon from 'assets/images/icons/heart.svg';
import checked from 'assets/images/icons/checked-icon-white.svg';
import backIcon from 'assets/images/icons/back-arrow.svg';
import userAdmin from 'assets/images/icons/user-admin.svg';
import edit from 'assets/images/icons/edit.svg';
import deleteIcon from 'assets/images/icons/trash.svg';
import horizontalEllipse from 'assets/images/icons/ellipse-horizontal.svg';
import userAdminRevoked from 'assets/images/icons/user-admin-colored.svg';
import navAddIcon from 'assets/images/icons/nav-add-icon.svg';
import mapPin from 'assets/images/icons/map-pin-white.svg';
import targetIcon from 'assets/images/icons/target.svg';
import ESLogoIcon from 'assets/images/icons/eslogo.svg';
import userLogo from 'assets/images/icons/user-logo.svg';
import searchIcon from 'assets/images/icons/search-icon.svg';
import rightArrow from 'assets/images/icons/right-arrow.svg';
import emailIcon from 'assets/images/verify-email/email.svg';
import checkedIconBlack from 'assets/images/icons/checked-icon-black.svg';
import trashIcon from 'assets/images/icons/trash-white.svg';
import playIcon from 'assets/images/icons/play.svg';
import attachIcon from 'assets/images/icons/paperclip.svg';
import donateIcon from 'assets/images/icons/heart-donate.svg';
import bookIcon from 'assets/images/icons/book-open-white.svg';
import commentIcon from 'assets/images/icons/comment.svg';
import nextIcon from 'assets/images/icons/right-slide.svg';
import prevIcon from 'assets/images/icons/left-slide.svg';
import chevronUp from 'assets/images/icons/chevron-up.svg';
import rightIcon from 'assets/images/icons/right-chevron.svg';
import chevronRight from 'assets/images/icons/chevron-right.svg';
import chevronRightNavy from 'assets/images/icons/right.svg';
import postIcon from 'assets/images/icons/book-open.svg';
import logoWhite from 'assets/images/logo/logo-white.svg';
import addIcon from 'assets/images/icons/user-plus.svg';
import plus from 'assets/images/icons/plus-icon.svg';
import emptyLogo from 'assets/images/icons/whiteLogo.svg';
import playIconBlack from 'assets/images/icons/play-black.svg';
import cancelWhite from 'assets/images/icons/cross_white.svg';
import checkWhite from 'assets/images/icons/check_white.svg';
import favIconWhite from 'assets/images/icons/white-heart.svg';
import favIconNavy from 'assets/images/icons/heart-navy.svg';
import manageIcon from 'assets/images/icons/network_management.svg';
import userPlus from 'assets/images/icons/user-plus-blue.svg';
import invite from 'assets/images/icons/invite.svg';
import email from 'assets/images/icons/email.svg';
import userWhite from 'assets/images/icons/user-white.svg';
import searchIconPeople from 'assets/images/icons/search.svg';
import removedIcon from 'assets/images/icons/remove-icon.svg';
import cancelIconVerifyEmail from 'assets/images/verify-email/cancel.svg';
import bigCheckedIcon from 'assets/images/verify-email/check.svg';
import logoutIcon from 'assets/images/icons/log-out-white.svg';
import plusIconNavy from 'assets/images/icons/plus_navy.svg';
import chevronDown from 'assets/images/icons/chevron-down.svg';
import chevronUpside from 'assets/images/icons/chevron_up.svg';

const IMAGES = {
  intro1,
  intro2,
  intro3,
  greenTick,
  defaultProfilePic,
  ESLogo,
  ESText,
  yourSupportHeart,
  cancelIcon,
  validIcon,
  invalidIcon,
  eyeIcon,
  eyeOffIcon,
  mapIcon,
  userIcon,
  plusIcon,
  minusIcon,
  profilePictureNetwork,
  crossIcon,
  uploadIcon,
  editImageIcon,
  calendar,
  emptyMilestone,
  milestoneIcon,
  callIcon,
  editIcon,
  whatsappIcon,
  facebookIcon,
  instagramIcon,
  chevronRightNavy,
  calling,
  whatsapp,
  mail,
  userIconEdit,
  usersIcon,
  favoriteDefault,
  heartIcon,
  checked,
  backIcon,
  userAdmin,
  edit,
  horizontalEllipse,
  userAdminRevoked,
  deleteIcon,
  navAddIcon,
  mapPin,
  targetIcon,
  ESLogoIcon,
  searchIcon,
  userLogo,
  rightArrow,
  emailIcon,
  checkedIconBlack,
  trashIcon,
  playIcon,
  attachIcon,
  donateIcon,
  bookIcon,
  commentIcon,
  prevIcon,
  nextIcon,
  chevronRight,
  chevronUp,
  rightIcon,
  postIcon,
  logoWhite,
  addIcon,
  plus,
  emptyLogo,
  playIconBlack,
  cancelWhite,
  checkWhite,
  favIconWhite,
  favIconNavy,
  manageIcon,
  userPlus,
  invite,
  email,
  userWhite,
  searchIconPeople,
  removedIcon,
  bigCheckedIcon,
  cancelIconVerifyEmail,
  logoutIcon,
  plusIconNavy,
  chevronDown,
  chevronUpside,
};
export default IMAGES;
