import styled from 'styled-components';

export const NavbarStyled = styled.div`
  position: fixed;
  width: 100%;
  height: 80px;
  display: flex;
  bottom: 0;
  left: 0;
  background: var(--default-interface-white);
  box-shadow: 0px 8px 24px var(--default-interface-gray-500);
  z-index: 1;
  .logo-icon{
    display: none;
  }
  ul {
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 32px;
    li {
        list-style: none;
        button{
            width: 48px;
            height: 48px;
            outline: none;
            border-radius: 8px;
            border: 2px solid transparent;
            background: var(--default-interface-white);
            display: grid;
            place-items: center;
            transition: 0.3s all ease-in;
            &.active{
                cursor: pointer;
                background: var(--default-interface-indigo-400);
                box-shadow: 0px 4px 16px rgba(212, 206, 210, 0.25);
                svg, path{
                    stroke: var(--default-interface-white);
                }
            }
            &:hover{
                cursor: pointer;
                border: 2px solid var(--default-interface-indigo-400);
                box-shadow: 0px 4px 16px rgba(212, 206, 210, 0.25);
            }
        }
    }
  }
  @media only screen and (min-width: 769px) {
    width: 80px;
    top: 0;
    left: 0;
    height: 100%;
    padding-top: 32px;
    box-shadow: none;
    flex-direction: column;
    align-items: center;
    .logo-icon{
      display: block;
      background: transparent;
      border: 0;
      outline: none;
      cursor: pointer;
      margin-bottom: 56px;
    }
    ul {
        padding: 0;
        flex-direction: column;
        gap: 8px;
        justify-content: initial;
    }
  }
`;
