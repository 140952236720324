import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { USER_INFO_TYPE } from '../../features/profile/types';
import { NETWORK_DATA } from './types';

export type AppState = {
  header: string;
  user: USER_INFO_TYPE;
  network: NETWORK_DATA;
}

const initialUserState = {
  about_you: {
    id: 0,
    refers_me_as: '',
    signature: null,
    user_id: 0,
  },
  address: {
    address: '',
    children_id: 0,
    id: 0,
    lat: '',
    long: '',
    share: false,
    user_id: 0,
  },
  user: {
    birthdate: '',
    created_at: '',
    email: '',
    email_verified_at: '',
    first_name: '',
    id: 0,
    last_name: '',
    profile_picture: '',
    updated_at: '',
    role_id: null,
    role: '',
    user_role: [],
  },
  contact_information: {
    children_id: 0,
    country_code: '',
    facebook: '',
    id: 0,
    instagram: '',
    phone_number: '',
    share: false,
    whatsapp: '',
  },
  network: null,
  role: '',
  user_help_tags: [
    {
      help_category_tag_id: 1,
      help_category_id: 1,
      id: 0,
      others: null,
      user_id: 0,
      tag: '',
      unicode: '',
    },
  ],
  is_profile_complete: false,
  your_relation: '',
};
const initialNetworkData = {
  children: [
    {
      id: 0,
      first_name: '',
      birthdate: '',
      last_name: '',
      profile_picture: null,
    },
  ],
  partner_guardian: [],
  family: [],
  friend: [],
  network_request: [],
  bulk_invitee: [],
};

const initialState: AppState = {
  header: '',
  user: initialUserState,
  network: initialNetworkData,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setHeader: (state, { payload }: PayloadAction<string>) => {
      state.header = payload;
    },
    setUserInfo: (state, { payload }: PayloadAction<USER_INFO_TYPE>) => {
      state.user = payload;
    },
    setNetworkInfo: (state, { payload }: PayloadAction<NETWORK_DATA>) => {
      state.network = payload;
    },
    clearAppData: (state) => {
      state.user = initialUserState;
      state.network = initialNetworkData;
    },
  },
});

export const {
  setHeader,
  setUserInfo,
  setNetworkInfo,
  clearAppData,
} = appSlice.actions;

export default appSlice.reducer;
