import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialSignUpNetworkData } from 'features/signUpNetwork/initialNetworkData';
import { SupporterHelpingCategoryType } from '../supporterNetwork/types';
import {
  User,
  SignUpNetworkData,
  SupporterSignupUser,
  SupporterSignature,
  ChildSignupUser,
  ProfilePictureData,
  OnboardingState,
} from './types';

export type SignUpState = {
  user: User;
  favoriteThings: SupporterHelpingCategoryType[];
  signUpUserData: {
    first_name: string;
    last_name: string;
    id: number | null;
    email: string;
  }
  signUpNetworkData: SignUpNetworkData;
  childrenFormNumber: number;
  isChildrenFormEdit: boolean;
  allCheckDataBack: boolean;
  isComingFromChildEmailNoEdit: boolean;
  supporterUser: SupporterSignupUser;
  childUser: ChildSignupUser;
  supporterSignature: SupporterSignature,
  profilePictureData: ProfilePictureData,
  helpingCategoriesData: SupporterHelpingCategoryType[];
  onboardingState: OnboardingState;
}
const initialUserState: User = {
  id: null,
  firstName: '',
  lastName: '',
  dobDay: '',
  dobMonth: '01',
  dobYear: '',
  email: '',
  password: '',
};

const initialSupporterUserState: SupporterSignupUser = {
  id: null,
  firstName: '',
  lastName: '',
  dobDay: '',
  dobMonth: '01',
  dobYear: '',
  password: '',
  token: '',
};

const initialSupporterSignatureState: SupporterSignature = {
  user_id: 0,
  refers_me_as: '',
  signature: '',
};

export const initialOnboardingState: OnboardingState = {
  profilePictureAdded: false,
  helpTagsAdded: false,
  helpTagsCount: 0,
  addressAdded: false,
  addressShared: false,
};

const initialState: SignUpState = {
  user: initialUserState,
  favoriteThings: [],
  signUpUserData: {
    first_name: '',
    last_name: '',
    id: null,
    email: '',
  },
  childUser: {
    password: '',
  },
  signUpNetworkData: initialSignUpNetworkData,
  childrenFormNumber: 0,
  allCheckDataBack: false,
  isChildrenFormEdit: false,
  isComingFromChildEmailNoEdit: false,
  supporterUser: initialSupporterUserState,
  supporterSignature: initialSupporterSignatureState,
  profilePictureData: null,
  helpingCategoriesData: {} as SupporterHelpingCategoryType[],
  onboardingState: initialOnboardingState,
};

export const SignUpSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    setUserData: (
      state,
      { payload: { user } }: PayloadAction<{ user: User }>,
    ) => {
      state.user = user;
    },
    clearData: (
      state,
    ) => {
      state.user = initialUserState;
    },
    setSignUpNetworkData: (
      state,
      { payload: { signUpNetworkData } }: PayloadAction<{ signUpNetworkData: SignUpNetworkData }>,
    ) => {
      state.signUpNetworkData = signUpNetworkData;
    },
    setUserPassword: (
      state,
      { payload: { password } }: PayloadAction<{ password: string }>,
    ) => {
      state.user = { ...state.user, password };
    },
    setSignUpUserData: (
      state,
      { payload: { signUpUserData } }: PayloadAction<{ signUpUserData: { first_name: string; last_name: string; email: string; id: number;} }>,
    ) => {
      state.signUpUserData = signUpUserData;
    },
    setChildrenFormNumber: (state, { payload }: PayloadAction<number>) => {
      state.childrenFormNumber = payload;
    },
    setChildrenFormEdit: (state, { payload }: PayloadAction<boolean>) => {
      state.isChildrenFormEdit = payload;
    },
    setAllCheckDataBack: (state, { payload }: PayloadAction<boolean>) => {
      state.allCheckDataBack = payload;
    },
    setIsComingFromChildEmailNoEdit: (state, { payload }: PayloadAction<boolean>) => {
      state.isComingFromChildEmailNoEdit = payload;
    },
    setSupporterSignupData: (
      state,
      { payload: { supporterUser } }: PayloadAction<{ supporterUser: SupporterSignupUser }>,
    ) => {
      state.supporterUser = supporterUser;
    },
    setChildSignupData: (
      state,
      { payload: { childUser } }: PayloadAction<{ childUser: ChildSignupUser }>,
    ) => {
      state.childUser = childUser;
    },
    setSupporterSignatureData: (
      state,
      { payload: { supporterSignature } }: PayloadAction<{ supporterSignature: SupporterSignature }>,
    ) => {
      state.supporterSignature = supporterSignature;
    },
    setProfilePictureData: (
      state,
      { payload: { profilePictureData } }: PayloadAction<{ profilePictureData: ProfilePictureData }>,
    ) => {
      state.profilePictureData = profilePictureData;
    },
    setHelpingCategoryData: (
      state,
      { payload: { helpingCategoriesData } }: PayloadAction<{ helpingCategoriesData: SupporterHelpingCategoryType[] }>,
    ) => {
      state.helpingCategoriesData = helpingCategoriesData;
    },
    setChildFavoriteThings: (
      state,
      { payload: { data } }: PayloadAction<{ data: SupporterHelpingCategoryType[] }>,
    ) => {
      state.favoriteThings = data;
    },
    setOnboardingState: (
      state,
      { payload: { data } }: PayloadAction<{ data: OnboardingState }>,
    ) => {
      state.onboardingState = data;
    },
    clearSignUpData: (state) => {
      state.user = initialUserState;
      state.signUpUserData = {
        first_name: '',
        last_name: '',
        email: '',
        id: null,
      };
      state.childUser = {
        password: '',
      };
      state.signUpNetworkData = initialSignUpNetworkData;
      state.onboardingState = initialOnboardingState;
      state.childrenFormNumber = 0;
      state.isChildrenFormEdit = false;
      state.isComingFromChildEmailNoEdit = false;
      state.supporterUser = initialSupporterUserState;
      state.supporterSignature = initialSupporterSignatureState;
      state.profilePictureData = null;
      state.helpingCategoriesData = {} as SupporterHelpingCategoryType[];
    },
  },
});

export const {
  setUserData,
  setOnboardingState,
  setSignUpNetworkData,
  setUserPassword,
  setSignUpUserData,
  setChildrenFormNumber,
  setChildrenFormEdit,
  setChildFavoriteThings,
  setIsComingFromChildEmailNoEdit,
  clearData,
  setSupporterSignupData,
  setSupporterSignatureData,
  setChildSignupData,
  setProfilePictureData,
  setHelpingCategoryData,
  clearSignUpData,
  setAllCheckDataBack,
} = SignUpSlice.actions;

export default SignUpSlice.reducer;
