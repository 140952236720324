import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { USER_PROFILE } from 'features/profile/types';
import { NETWORK_DATA } from 'common/store/types';

export type SignUpState = {
  networkData: NETWORK_DATA;
  typeToSearch: string;
  currentUserSelected: USER_PROFILE | null;
  showProfileView: boolean;
  profileOpenedFromList: boolean;
}

const initialNetworkState = {
  children: [
    {
      id: 0,
      birthdate: '',
      first_name: '',
      last_name: '',
      profile_picture: null,
    },
  ],
  partner_guardian: [],
  family: [],
  friend: [],
  bulk_invitee: [],
  network_request: [],
};
const initialState: SignUpState = {
  networkData: initialNetworkState,
  typeToSearch: '',
  currentUserSelected: null,
  showProfileView: false,
  profileOpenedFromList: false,
};

export const PeopleSlice = createSlice({
  name: 'people',
  initialState,
  reducers: {
    setTypeToSearch: (state, { payload }: PayloadAction<string>) => {
      state.typeToSearch = payload;
    },
    setNetworkData: (state, { payload }: PayloadAction<NETWORK_DATA>) => {
      state.networkData = payload;
    },
    setCurrentUserSelected: (state, { payload } : PayloadAction<USER_PROFILE | null>) => {
      state.currentUserSelected = payload;
    },
    setShowProfileView: (state, { payload } : PayloadAction<boolean>) => {
      state.showProfileView = payload;
    },
    setProfileOpenedFromList: (state, { payload } : PayloadAction<boolean>) => {
      state.profileOpenedFromList = payload;
    },
    clearPeopleData: (state) => {
      state.showProfileView = false;
      state.typeToSearch = '';
      state.networkData = initialNetworkState;
      state.currentUserSelected = null;
      state.profileOpenedFromList = false;
    },
  },
});

export const {
  setTypeToSearch,
  clearPeopleData,
  setNetworkData,
  setCurrentUserSelected,
  setShowProfileView,
  setProfileOpenedFromList,
} = PeopleSlice.actions;

export default PeopleSlice.reducer;
