import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { POST_TYPE } from './types';

export type SignUpState = {
  posts: Array<POST_TYPE>;
  profilePosts: Array<POST_TYPE>;
}

const initialState: SignUpState = {
  posts: [],
  profilePosts: [],
};

export const PeopleSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setHomePosts: (state, { payload } : PayloadAction<Array<POST_TYPE>>) => {
      state.posts = payload;
    },
    setProfilePosts: (state, { payload } : PayloadAction<Array<POST_TYPE>>) => {
      state.profilePosts = payload;
    },
    clearHomeData: (state) => {
      state.posts = [];
      state.profilePosts = [];
    },
  },
});

export const {
  setHomePosts,
  setProfilePosts,
  clearHomeData,
} = PeopleSlice.actions;

export default PeopleSlice.reducer;
