import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import appSlice from 'common/store/appSlice';
import authSlice from 'features/login/authSlice';
import signUpSlice from 'features/signUp/SignUpSlice';
import peopleSlice from 'features/people/PeopleSlice';
import milestonesSlice from 'features/milestone/MilestonesSlice';
import HomeSlice from 'features/home/HomeSlice';
import AskForHelpSlice from 'features/askForHelp/AskForHelpSlice';
import { rtkQueryErrorHandler } from 'common/utils/rtkErrorHandler';
import { baseApi } from 'common/store/baseApi';

export const rootReducer = combineReducers({
  app: appSlice,
  auth: authSlice,
  signUp: signUpSlice,
  home: HomeSlice,
  people: peopleSlice,
  milestones: milestonesSlice,
  askForHelp: AskForHelpSlice,
  [baseApi.reducerPath]: baseApi.reducer,
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: [baseApi.reducerPath],
};

export const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat(baseApi.middleware, rtkQueryErrorHandler),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
