import React, { memo, Suspense } from 'react';
import { linkPath } from 'common/constants';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import Loader from 'common/designSystem/loader';
import { AuthRoute } from 'common/designSystem/AuthRoute';
const Intro = React.lazy(() => import('./intro'));
const SignUp = React.lazy(() => import('./signUp'));
const CookiesPolicy = React.lazy(() => import('./cookiesPolicy'));
const VerifyEmail = React.lazy(() => import('./verifyEmail'));
const VerifiedEmail = React.lazy(() => import('./verifiedEmail'));
const Network = React.lazy(() => import('./signUpNetwork'));
const SignIn = React.lazy(() => import('./login'));
const ChildOnboarding = React.lazy(() => import('./childOnboarding'));
const SupporterNetwork = React.lazy(() => import('./supporterNetwork'));
const ForgotPassword = React.lazy(() => import('./forgotPassword'));
const ResetPassword = React.lazy(() => import('./resetPassword'));
const Home = React.lazy(() => import('./home'));
const Profile = React.lazy(() => import('./profile'));
const Settings = React.lazy(() => import('./settings'));
const People = React.lazy(() => import('./people'));
const Milestone = React.lazy(() => import('./milestone'));
const Update = React.lazy(() => import('./update'));
const OthersProfile = React.lazy(() => import('./othersProfile'));
const ManageChildren = React.lazy(() => import('./manageChildren'));
const MilestonesGallery = React.lazy(() => import('./milestonesGallery'));
const NetworkManagement = React.lazy(() => import('./networkManagement'));
const RemovedFromNetwork = React.lazy(() => import('./removedFromNetwork'));
const SelectNetwork = React.lazy(() => import('./selectNetwork'));

const WebRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <Routes>
          <Route path={linkPath.intro} element={<Intro />} />
          <Route path={linkPath.signUp} element={<SignUp />} />
          <Route path={linkPath.cookiesPolicy} element={<CookiesPolicy />} />
          <Route path={linkPath.verifyEmail} element={<VerifyEmail />} />
          <Route path={linkPath.accountVerified} element={<VerifiedEmail />} />
          <Route path={linkPath.signUpNetwork} element={<Network />} />
          <Route path={linkPath.login} element={<SignIn />} />
          <Route path={linkPath.guardianNetwork} element={<SupporterNetwork />} />
          <Route path={linkPath.childOnboarding} element={<ChildOnboarding />} />
          <Route path={linkPath.supporterNetwork} element={<SupporterNetwork />} />
          <Route path={linkPath.forgotPassword} element={<ForgotPassword />} />
          <Route path={linkPath.resetPassword} element={<ResetPassword />} />
          <Route path={linkPath.selectNetwork} element={<SelectNetwork />} />
          <Route path={linkPath.removedFromNetwork} element={<RemovedFromNetwork />} />
          <Route path={linkPath.home} element={<AuthRoute component={Home} />} />
          <Route path={linkPath.homeWithMilestone} element={<AuthRoute component={Home} />} />
          <Route path={linkPath.profile} element={<AuthRoute component={Profile} />} />
          <Route path={linkPath.othersProfile} element={<AuthRoute component={OthersProfile} />} />
          <Route path={linkPath.manageChildren} element={<AuthRoute component={ManageChildren} />} />
          <Route path={linkPath.milestonesGallery} element={<AuthRoute component={MilestonesGallery} />} />
          <Route path={linkPath.milestone} element={<AuthRoute component={Milestone} />} />
          <Route path={linkPath.people} element={<AuthRoute component={People} />} />
          <Route path={linkPath.settings} element={<AuthRoute component={Settings} />} />
          <Route path={linkPath.networkManagement} element={<AuthRoute component={NetworkManagement} />} />
          <Route path={linkPath.update} element={<Update />} />
        </Routes>
      </Router>
    </Suspense>
  );
};

export default memo(WebRoutes);
