/* eslint-disable @typescript-eslint/naming-convention */
import runtimeEnv from '@mars/heroku-js-runtime-env';

const { REACT_APP_API_URL, REACT_APP_GOOGLE_MAPS_API_KEY, REACT_APP_MIXPANEL_TOKEN } = runtimeEnv();

export const apiRootUrl = `${REACT_APP_API_URL}/api/v1`;
export const mapAPIKey = `${REACT_APP_GOOGLE_MAPS_API_KEY}`;
export const mixpanelToken = `${REACT_APP_MIXPANEL_TOKEN}`;
// RTK Query

export const signInUrl = '/login';
export const logoutUrl = '/logout';
export const signUpUrl = '/register';
export const getNetworksUrl = '/users/%userId%/networks';
export const verifyEmailUrl = '/verify/%token%';
export const resendVerifyEmail = '/resend';
export const networkOptionUrl = '/network-option';
export const createNetworkUrl = '/network';
export const supporterSignUpUrl = '/invite-create';
export const forgotPassword = '/forgot-password';
export const resetPasswordURL = '/reset-password';
export const validateResetPasswordURL = '/reset-password/%token%';
export const supporterAcceptInvitUrl = '/accept/%token%';
export const supporterAddAboutYouUrl = '/about-you';
export const supporterPhotoPreSignedURL = '/presigned-url';
export const supporterPhotoKeyURL = '/user';
export const supporterPhotoKeyUpdateURL = '/profile-picture';
export const supporterGetHelpingTagsURL = '/help-categories';
export const childGetHelpingTagsURL = '/help-categories?child=true';
export const supporterAddHelpingTagsURL = '/user-help-category';
export const userAddFavoriteTagsURL = '/user-favourite-category';
export const getPeopleBasedOnCategory = '/category/%id%/people';
export const postAskForHelp = '/request-help';
export const supporterAddAddress = '/add-address';
export const supporterUpdateAddress = '/address/%id%/update';
export const supporterAddContact = '/add-contact';
export const getUserInfoUrl = '/user-info?network_id=%networkId%';
export const getNetworkInfoUrl = '/network?network_id=%networkId%';
export const supporterUpdateContact = '/contact/%contactId%/update';
export const makeAdminUrl = '/make-admin';
export const removeAdminUrl = '/remove-admin';
export const userUpdateUrl = '/user/update';
export const settingsProfileUpdate = '/user/%userId%/update';
export const acceptNetworkRequest = '/invite/%inviteId%/approve';
export const declineNetworkRequest = '/invite/%inviteId%/decline';
export const getPublicProfile = '/user/%userId%/profile?network_id=%networkId%';
export const getChildProfile = '/user/%userId%/profile?network_id=%networkId%';
export const peopleInvite = '/send-invite';
export const bulkInvite = '/bulk-invite';
export const childInvite = '/invite';
export const favPeople = '/favourite-people';
export const favPeopleDelete = '/favourite-people/%favId%/delete';
export const createPost = '/post';
export const updatePost = '/post/%postId%/update';
export const updateComment = '/comments/%commentId%';
export const addNewComment = '/comment';
export const getPost = '/network/%networkId%/post?page=%pageId%';
export const getFilteredPost = '/network/%networkId%/post?page=%pageId%&type=%postType%';
export const getPostsSelf = '/network/%networkId%/post?self=true&page=%pageId%';
export const removeThisPerson = '/user/%userId%/remove';
export const removePost = '/post/%postId%/delete';
export const removeComment = '/comments/%commentId%';
export const newMilestone = '/milestone';
export const getMilestonePostsURL = '/milestone/%milestoneId%/post?page=%pageId%';
export const getMilestones = '/network/%networkId%/milestone?d=true';
export const getMilestonesWithChildId = `/network/%networkId%/milestone?d=true${`&child_id=${'%childId%'}`}`;
export const deleteMilestoneUrl = '/milestone/%milestoneId%/delete';
export const updateMilestoneUrl = '/milestone/%milestoneId%/update';
export const verifyEmailInNetworkURL = '/check-email';
export const getFavoriteThings = '/favourite-categories';
export const childProfileMilestoneViewUrl = '/milestone/%childId%/child-milestone';
