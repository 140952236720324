import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChildHelpingCategoryType } from 'features/supporterNetwork/types';
import { FAVORITE_USER_TYPE } from 'features/profile/types';
import { PeopleType } from './types';

export type AskForHelpState = {
  categories: ChildHelpingCategoryType[];
  peopleBasedOnCategory: PeopleType[] | FAVORITE_USER_TYPE[];
  flowSelected: null | string;
  categorySelected: null | number;
  subCategorySelected: null | number;
  favPersonSelected: null | Array<number>;
  peopleSelectedBasedOnCategory: null | Array<number>;
  favPeopleSelectedCustomQuestion: null | Array<number>;
  customQuestion: string;
  allSelectedPeople: null | Array<number>;
  topic: string;
}

const initialState: AskForHelpState = {
  flowSelected: null,
  categorySelected: null,
  categories: [],
  subCategorySelected: null,
  favPeopleSelectedCustomQuestion: null,
  favPersonSelected: null,
  peopleSelectedBasedOnCategory: null,
  customQuestion: '',
  peopleBasedOnCategory: [],
  allSelectedPeople: [],
  topic: '',
};

export const AskForHelpSlice = createSlice({
  name: 'askForHelp',
  initialState,
  reducers: {
    setFlowSelected: (state, { payload }: PayloadAction<string | null>) => {
      state.flowSelected = payload;
    },
    setCategorySelected: (state, { payload }: PayloadAction<number | null>) => {
      state.categorySelected = payload;
    },
    setSubCategorySelected: (state, { payload }: PayloadAction<number | null>) => {
      state.subCategorySelected = payload;
    },
    setCustomQuestion: (state, { payload }: PayloadAction<string>) => {
      state.customQuestion = payload;
    },
    setTopic: (state, { payload }: PayloadAction<string>) => {
      state.topic = payload;
    },
    setFavPersonSelected: (state, { payload }: PayloadAction<Array<number> | null>) => {
      state.favPersonSelected = payload;
    },
    setPeopleSelectedBasedOnCategory: (state, { payload }: PayloadAction<Array<number> | null>) => {
      state.peopleSelectedBasedOnCategory = payload;
    },
    setFavPeopleSelectedCustomQuestion: (state, { payload }: PayloadAction<Array<number> | null>) => {
      state.favPeopleSelectedCustomQuestion = payload;
    },
    setCategories: (
      state,
      { payload }: PayloadAction<ChildHelpingCategoryType[]>,
    ) => {
      state.categories = payload;
    },
    setPeopleBasedOnCategory: (
      state,
      { payload }: PayloadAction<PeopleType[] | FAVORITE_USER_TYPE[]>,
    ) => {
      state.peopleBasedOnCategory = payload;
    },
    setAllSelectedPeople: (state, { payload }: PayloadAction<Array<number> | null>) => {
      state.allSelectedPeople = payload;
    },
    clearAskForHelpData: (state) => {
      state.flowSelected = null;
      state.topic = '';
      state.peopleSelectedBasedOnCategory = null;
      state.favPeopleSelectedCustomQuestion = null;
      state.categorySelected = null;
      state.favPersonSelected = null;
      state.peopleBasedOnCategory = [];
      state.customQuestion = '';
      state.subCategorySelected = null;
      state.allSelectedPeople = null;
    },
  },
});

export const {
  setFavPeopleSelectedCustomQuestion,
  setPeopleBasedOnCategory,
  setTopic,
  setPeopleSelectedBasedOnCategory,
  setCategories,
  setCustomQuestion,
  setFlowSelected,
  setFavPersonSelected,
  setCategorySelected,
  setSubCategorySelected,
  clearAskForHelpData,
  setAllSelectedPeople,
} = AskForHelpSlice.actions;

export default AskForHelpSlice.reducer;
