export const mobileWidthLimit = 667;
export const tabletWidthLimit = 1120;
export const uploadLimitInBytes = 10000000;
export const uploadLimitInBytesForPost = 20000000;

export const months = [
  { value: '01', displayValue: 'Jan' },
  { value: '02', displayValue: 'Feb' },
  { value: '03', displayValue: 'Mar' },
  { value: '04', displayValue: 'Apr' },
  { value: '05', displayValue: 'May' },
  { value: '06', displayValue: 'June' },
  { value: '07', displayValue: 'July' },
  { value: '08', displayValue: 'Aug' },
  { value: '09', displayValue: 'Sept' },
  { value: '10', displayValue: 'Oct' },
  { value: '11', displayValue: 'Nov' },
  { value: '12', displayValue: 'Dec' },
];

export const relationToYourPartnerOptions = [
  { value: '01', displayValue: 'Partner' },
  { value: '02', displayValue: 'Spouse' },
];

export const passedAwayRelationOptions = [
  { value: '01', displayValue: 'Friend' },
  { value: '02', displayValue: 'Family' },
];

export const relationToYouChildrenOptions = [
  { value: '01', displayValue: 'Mother' },
  { value: '02', displayValue: 'Father' },
  { value: '03', displayValue: 'Adoptive Mother' },
  { value: '04', displayValue: 'Adoptive Father' },
  { value: '05', displayValue: 'Foster Mother' },
  { value: '06', displayValue: 'Foster Father' },
  { value: '07', displayValue: 'Stepmother' },
  { value: '08', displayValue: 'Stepfather' },
];

export const linkPath = {
  intro: '/',
  signUp: '/signup',
  login: '/login',
  cookiesPolicy: '/cookies-policy',
  verifyEmail: '/verify-email',
  accountVerified: '/email-verification/:token',
  signUpNetwork: '/signup/network',
  home: '/home',
  homeWithMilestone: '/home?d=:milestoneId',
  profile: '/profile',
  othersProfile: '/profile/:type/:userId',
  manageChildren: '/profile/manage/:userId',
  milestone: '/milestone',
  people: '/people',
  settings: '/settings',
  networkManagement: '/people/network-management',
  supporterNetwork: '/signup/supporter/:token?',
  childOnboarding: '/signup/children/:token?',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password/:token',
  selectNetwork: '/select-network',
  removedFromNetwork: '/removed',
  guardianNetwork: '/signup/guardian/:token?',
  update: '/update/:action',
  milestonesGallery: '/milestone/:milestoneId',
};

export const donateLink = 'https://www.elizabeth.org/donate';
export const elizabethLink = 'https://www.elizabeth.org/';

export const yourRelationshipIamFamily = [
  { value: '01', displayValue: 'Family' },
  { value: '02', displayValue: 'Friend' },
];

export const yourRelationshipIamChild = [
  { value: '01', displayValue: 'Aunt' },
  { value: '02', displayValue: 'Uncle' },
  { value: '03', displayValue: 'Friend' },
  { value: '04', displayValue: 'Brother' },
  { value: '05', displayValue: 'Sister' },
  { value: '06', displayValue: 'Grandmother' },
  { value: '07', displayValue: 'Grandfather' },
  { value: '08', displayValue: 'Biological mother' },
  { value: '09', displayValue: 'Biological father' },
  { value: '10', displayValue: 'Step-mother' },
  { value: '11', displayValue: 'Step-father' },
  { value: '12', displayValue: 'Half-brother' },
  { value: '13', displayValue: 'Half-sister' },
  { value: '14', displayValue: 'Cousin' },
];

export const familyRelationshipIamChild = [
  { value: '01', displayValue: 'Mother' },
  { value: '02', displayValue: 'Father' },
  { value: '03', displayValue: 'Adoptive Mother' },
  { value: '04', displayValue: 'Foster Mother' },
  { value: '05', displayValue: 'Foster Father' },
  { value: '06', displayValue: 'Stepmother' },
  { value: '07', displayValue: 'Stepfather' },
  { value: '08', displayValue: 'Guardian' },
];

export const signupNetworkSteps = [
  {
    label: 'Account',
    step: 1,
  },
  {
    label: 'Network',
    step: 2,
  },
  {
    label: 'Family',
    step: 3,
  },
  {
    label: 'Complete',
    step: 4,
  },
];
export const supporterNetworkSteps = [
  {
    label: 'Account',
    step: 1,
  },
  {
    label: 'Network',
    step: 2,
  },
  {
    label: 'Complete',
    step: 3,
  },
];

export const addTo = [
  { value: '01', displayValue: 'Guardians' },
  { value: '02', displayValue: 'Family' },
  { value: '03', displayValue: 'Friends' },
];

export const guardianRelations = [
  { value: '01', displayValue: 'Father' },
  { value: '02', displayValue: 'Mother' },
  { value: '03', displayValue: 'Adoptive Father' },
  { value: '04', displayValue: 'Adoptive Mother' },
  { value: '05', displayValue: 'Biological mother' },
  { value: '06', displayValue: 'Biological father' },
  { value: '07', displayValue: 'Foster Father' },
  { value: '08', displayValue: 'Foster Mother' },
  { value: '09', displayValue: 'Stepfather' },
  { value: '10', displayValue: 'Stepmother' },
  { value: '11', displayValue: 'Grandfather' },
  { value: '12', displayValue: 'Grandmother' },
  { value: '13', displayValue: 'Uncle' },
  { value: '14', displayValue: 'Aunt' },
  { value: '15', displayValue: 'Brother' },
  { value: '16', displayValue: 'Sister' },
  { value: '17', displayValue: 'Half-brother' },
  { value: '18', displayValue: 'Half-sister' },
  { value: '19', displayValue: 'Cousin' },
];

export const familyRelations = [
  { value: '01', displayValue: 'Adoptive Father' },
  { value: '02', displayValue: 'Adoptive Mother' },
  { value: '03', displayValue: 'Biological mother' },
  { value: '04', displayValue: 'Biological father' },
  { value: '05', displayValue: 'Foster Father' },
  { value: '06', displayValue: 'Foster Mother' },
  { value: '07', displayValue: 'Stepfather' },
  { value: '08', displayValue: 'Stepmother' },
  { value: '09', displayValue: 'Grandfather' },
  { value: '10', displayValue: 'Grandmother' },
  { value: '11', displayValue: 'Uncle' },
  { value: '12', displayValue: 'Aunt' },
  { value: '13', displayValue: 'Brother' },
  { value: '14', displayValue: 'Sister' },
  { value: '15', displayValue: 'Half-brother' },
  { value: '16', displayValue: 'Half-sister' },
  { value: '17', displayValue: 'Cousin' },
];

export const friendRelations = [
  { value: '01', displayValue: 'Half-brother' },
  { value: '02', displayValue: 'Half-sister' },
  { value: '03', displayValue: 'Cousin' },
  { value: '04', displayValue: 'Friend' },
];

// eslint-disable-next-line no-shadow
export enum POST_TYPES {
  ALL = 0,
  TEXT = 1,
  REMINDER = 2,
  PHOTO = 3,
  VIDEO = 4,
  MEDIA = 5,
  NEW_USER_ADDED = 6,
}

export const COMMENT_INITIAL_COUNT = 3;
