import styled from 'styled-components';
import IMAGES from 'common/designSystem/iconComponents/iconExports';
import LogoIcon from './LogoIcon';
import { LogoIconESType } from './types';

const LogoWrapper = styled.div`
    display: flex;
    .logo-text{
        margin-left: 6px;
    }
`;
const LogoIconES = ({ showESLogoText = true } : LogoIconESType) => (
  <LogoWrapper data-cy="es-logo">
    <LogoIcon />
    {showESLogoText ? <img className="logo-text" src={IMAGES.ESText} /> : ''}
  </LogoWrapper>
);
export default LogoIconES;
