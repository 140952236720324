import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MILESTONE_TYPE } from './types';

export type SignUpState = {
  upcomingMilestones: Array<MILESTONE_TYPE> | [];
  recentMilestones: Array<MILESTONE_TYPE> | [];
}

const initialState: SignUpState = {
  upcomingMilestones: [],
  recentMilestones: [],
};

export const MilestonesSlice = createSlice({
  name: 'milestones',
  initialState,
  reducers: {
    setUpcomingMilestones: (state, { payload }: PayloadAction<Array<MILESTONE_TYPE>>) => {
      state.upcomingMilestones = payload;
    },
    setRecentMilestones: (state, { payload }: PayloadAction<Array<MILESTONE_TYPE>>) => {
      state.recentMilestones = payload;
    },
    clearMilestonesData: (state) => {
      state.upcomingMilestones = [];
      state.recentMilestones = [];
    },
  },
});

export const {
  setRecentMilestones,
  setUpcomingMilestones,
  clearMilestonesData,
} = MilestonesSlice.actions;

export default MilestonesSlice.reducer;
