import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import {
  AuthState, NetworkType,
} from './types';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    token: null,
    networks: [],
    selectedNetwork: null,
  } as AuthState,
  reducers: {
    setCredentials: (
      state,
      {
        payload: {
          token,
        },
      }: PayloadAction<{ token: string | null;}>,
    ) => {
      state.token = token;
    },
    setSelectedNetworkForUser: (state, { payload }: PayloadAction<NetworkType>) => {
      state.selectedNetwork = payload;
    },
    setNetworksForUser: (state, { payload }: PayloadAction<Array<NetworkType>>) => {
      state.networks = payload;
    },
    clearCredentials: (state) => {
      state.token = authSlice.getInitialState().token;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      state.token = authSlice.getInitialState().token;
    });
  },
});

export const {
  setSelectedNetworkForUser,
  setCredentials,
  clearCredentials,
  setNetworksForUser,
} = authSlice.actions;

export default authSlice.reducer;
