import { FC, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from 'common/hooks/state';
import { RootState } from 'common/store/store';
import Navbar from '../Navbar';
import { linkPath } from '../../constants';
import SidebarLayout from '../SidebarLayout';

interface PropType {
    component: React.FC;
}

export const AuthRoute: FC<PropType> = ({ component: Component }) => {
  useEffect(() => {
    document.body.style.background = 'var(--default-interface-gray-300)';
    return () => {
      document.body.style.background = 'var(--default-interface-white)';
    };
  }, []);
  // 1. Check user authentication status
  const isAuthenticated = useAppSelector((state: RootState) => state.auth.token);
  // 2. If authenticated, display component
  if (isAuthenticated) {
    return (
      <SidebarLayout>
        <Navbar />
        <Component />
      </SidebarLayout>
    );
  }
  // 3. If not authenticated, redirect to login
  return <Navigate to={linkPath.intro} />; // Return to login page.
};
